import http from "@/helpers/http";
import { msgErr } from "@/helpers/message";
import { pack } from "@/helpers/utils";
const service = "faUnidrinkProduct/";

export function getProductList(form) {
  return http.request({
    url: "/faUnidrinkProduct/listByPage",
    method: "POST",
    params: {
      page: form.currPage,
      pageSize: form.pageSize,
    },
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function addProduct(form) {
  return http.request({
    url: "/faUnidrinkProduct/save",
    method: "POST",
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function editProduct(form) {
  return http.request({
    url: "/faUnidrinkProduct/updateById",
    method: "POST",
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function toggleProduct(form, key) {
  return http.request({
    url: "/faUnidrinkProduct/updateById",
    method: "POST",
    data: {
      body: {
        id: form.id,
        [key]: form[key],
      },
    },
  });
}

export function deleteProduct(id) {
  return http.request({
    url: "/faUnidrinkProduct/deleteByIds",
    method: "POST",
    data: {
      body: {
        ids: [id],
      },
    },
  });
}

export async function getSubProductOptions(options, form) {
  const currPage = 1;
  const pageSize = 50;
  options.splice(0, options.length);
  const cond = Object.assign({}, form, { currPage, pageSize });
  const fn = async (form) => {
    await getProductList(form).then(({ code, result, message }) => {
      if (code !== "0000") {
        msgErr(message);
        return;
      }
      const { list, total } = result;
      options.push(...list);
      if (currPage * pageSize < total) {
        fn(currPage + 1, pageSize);
      }
    });
  };
  await fn(cond);
}

export function addAlternativeRule(rule) {
  return http.request({
    url: "/faUnidrinkProductRule/save",
    method: "POST",
    data: {
      body: {
        ...rule,
      },
    },
  });
}

export function editAlternativeRule(rule) {
  return http.request({
    url: "/faUnidrinkProductRule/updateById",
    method: "POST",
    data: {
      body: {
        ...rule,
      },
    },
  });
}

export function deleteAlternativeRule(ids) {
  return http.request({
    url: "/faUnidrinkProductRule/deleteByIds",
    method: "POST",
    data: {
      body: {
        ids,
      },
    },
  });
}

export function getAlternativeByProduct(productId) {
  return http.request({
    url: "/faUnidrinkProductRule/listByPage",
    method: "POST",
    params: {
      page: 1,
      pageSize: 999,
    },
    data: {
      body: {
        productId,
      },
    },
  });
}

export function getPromotionByProduct(productId) {
  return http.request({
    url: "/faUnidrinkProductPromotion/listByPage",
    method: "POST",
    params: {
      page: 1,
      pageSize: 999,
    },
    data: {
      body: {
        productId,
      },
    },
  });
}

export function addPromotion(form) {
  return http.request({
    url: "/faUnidrinkProductPromotion/save",
    method: "POST",
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function editPromotion(form) {
  return http.request({
    url: "/faUnidrinkProductPromotion/updateById",
    method: "POST",
    data: {
      body: {
        ...form,
      },
    },
  });
}

export function deletePromotion(ids) {
  return http.request({
    url: "/faUnidrinkProductPromotion/deleteByIds",
    method: "POST",
    data: {
      body: {
        ids,
      },
    },
  });
}


export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}